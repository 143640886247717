"use strict";

import '../less/main.less';

(function($) {
    $(document).ready(function() {
        $('[data-scroll]').click(function() {
            let selector = $(this).data('scroll');
            let duration = $(this).data('scroll-duration') || 2000;

            console.log(selector, duration);

            $('html, body').animate({
                scrollTop: $(selector).offset().top
            }, duration);
        });
    });
})(jQuery);
